import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import format from 'date-fns/format';

import Users from '../../../api/Users';
import Products from '../../../api/Products';

import { determineEnvironment } from '../../../containers/Streaming/ArchivedStreams/ArchiveStreamsUtils'
import { addToCart } from '../../../utils/thunks';

import '../../POS/Events/ListCards/RegEventListCards.scss';
import Streams from '../../Streaming/streams.json'

export const Livestreams = props => {
    let history = useHistory();
    let dispatch = useDispatch();

    const user = useSelector(state => state.auth.user);
    const [loading, setLoading] = useState(false);
    const [pagePart, setPagePart] = useState();
    const [purchased, setPurchased] = useState();
    const [product, setProduct] = useState([]);

    const currentEnvironment = determineEnvironment();
    const product_ids = {"prod": 2058, "qa": 1532, "dev": 1278};
    const product_id = product_ids[currentEnvironment];
    
    
    useEffect(() => {
        let mounted = true;

        setLoading(true);
        Users.purchasedProduct({user_id: user?.profile?.id, product_id: [product_id]})
        .then(response => {
            if(mounted && !response.errors) {
                setPurchased(response.data[product_id]);
            }
            setLoading(false);
        }).catch(e => console.error(e));

        // cancel stuff when component unmounts
        // not sure this is even necessary
        return () => {
            mounted = false;
            setLoading(false);
        }        
    },[product_id, user]);

    useEffect(() => {
        let mounted = true;

        if(purchased === 0) {
            Products.get({id: product_id})
            .then(response => {
                if(mounted && response.data?.products) {
                    let item = response.data.products[0];
                    if (item) {
                        setProduct([{
                            category_id: item.categories[0]?.id,
                            discounts: 0,
                            hash: null,
                            id: item.id,
                            product_id: product_id,
                            product_type_id: item.product_type_id,
                            is_taxable: item.is_taxable,
                            original_price: +item.product_variants[0].price,
                            parent_id: null,
                            product_name: item.name,
                            product_price: +item.product_variants[0].price,
                            qty: 1,
                            type: 1,
                            variant_id: item.product_variants[0].id,
                            variant_name: item.product_variants[0].name
                        }]);
                    }
                } else console.error(response.errors);
            }).catch(e => console.error(e));
        }

        return () => mounted = false;
    },[purchased, product_id]);

    const clickHandler = useCallback(() => {

        if (purchased) {
            history.push("/p/livestreams");
        } else {
            dispatch(addToCart(product));
        }
    },[purchased, product, dispatch, history]);

    useEffect(() => {
        setPagePart(
            <>
                <div className="d-flex flex-row align-items-center">
                    <h6 className="mx-3">{Streams[0]?.name}</h6>
                    <Button onClick={clickHandler}>{ purchased ? "Watch" : "Purchase Livestream Access - $" + parseFloat(product?.[0]?.product_price)?.toFixed(2) }</Button>                          
                </div>
            </>
        );
    }, [purchased, product, clickHandler]);

    return (
        <React.Fragment>
            { pagePart }
        </React.Fragment>
    );
}